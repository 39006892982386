import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { Image as CloudinaryImage } from "cloudinary-react"
import { Grid } from "@material-ui/core"

import Img from "gatsby-image"
import {
    Link,
    graphql,
    useStaticQuery,
    navigate,
    prefetchPathname,
} from "gatsby"

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    bannerImage: {
        width: "100%",
    }
}))


const Products = () => {
    const classes = useStyles()
    const data = useStaticQuery(graphql`
  query {
    spitfire: file(relativePath: { eq: "cedar-solar-pumps-00-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    premium: file(relativePath: { eq: "cedar-solar-pumps-01-600x600.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    veichi: file(relativePath: { eq: "cedar-solar-pumps-00-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    multiflow: file(relativePath: { eq: "cedar-solar-pumps-00-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

    return (
        <Layout>
            <SEO title="Products" />
            <Container component="main" maxWidth="md">
                <Typography component="h1" variant="h5">
                    Products
                </Typography>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ padding: '10px', minHeight: '300px', minWidth: '300px' }}>
                            <Img fluid={data.spitfire.childImageSharp.fluid} />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            Spitfire Pumps
                        </Typography>
                        <Typography gutterBottom>
                            The Spitfire Pumps range is an economy pump with a 1 year warranty. This DC pump features a brushless motor and MPPT controller. There are 3 models available.
                        </Typography>
                        <Typography gutterBottom>
                            Models Available:
                        </Typography>

                        <Typography component="ul">
                            <li>Griffon 50</li>
                            <li>Griffon 80</li>
                            <li>Griffon 100</li>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ padding: '10px', minHeight: '300px', minWidth: '300px' }}>
                            <Img fluid={data.premium.childImageSharp.fluid} />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            Premium Solar Pumps
                        </Typography>
                        <Typography gutterBottom>
                            We have been selling our Premium Pump range of DC solar water pumps in South Africa for the last 10 years. The DC pump range works well for boreholes up to 112 meters deep. If your Total Dynamic Head is more or you need more water from your borehole the MultiFlow range or a pump drive solution will do the job.                        </Typography>
                        <Typography gutterBottom>
                            Models Available:
                        </Typography>

                        <Typography component="ul">
                            <li>Ceva 55</li>
                            <li>Ceva 80</li>
                            <li>Ceva 100</li>
                            <li>Bovem 70</li>
                            <li>Bovem 140</li>
                            <li>Fortis 130</li>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ padding: '10px', minHeight: '300px', minWidth: '300px' }}>
                            <Img fluid={data.multiflow.childImageSharp.fluid} />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            MultiFlow AC/DC Pumps
                        </Typography>
                        <Typography gutterBottom>
                            The MultiFlow range of AC/DC hybrid solar water pumps are fitted with a permanent magnet and brushless motor which enables the efficient use of energy from a wide range of supply options. The pump system offers the perfect water supply solution in remote areas where water is scarce and utility power supply is non-existent or unreliable. The motor is water filled and poses no pollution risk to drinking water. The controller is inside the motor to make the system easier to install and more reliable. The controller has MPPT (Maximum Power Point Tracking) functionality for optimal solar power generation.                        </Typography>

                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <div style={{ padding: '10px', minHeight: '300px', minWidth: '300px' }}>
                            <Img fluid={data.veichi.childImageSharp.fluid} />
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <Typography gutterBottom variant="h6" style={{ marginTop: '20px' }}>
                            Veichi Pump Drives
                        </Typography>
                        <Typography gutterBottom>
                            Veichi Drive systems work well for deep boreholes, larger yields and converting current AC pumps to solar power. We have installed solar Drive systems that deliver up to 80 000 liters of water per hour. The system consists of solar array, a Veichi drive to convert DC power to AC power & a AC water pump. With over a decade of experience our knowledgeable team that will ensure they system performs as planned.                        </Typography>
                        <Typography gutterBottom>
                            It is possible to convert pumps from 0.75kW to 200kW. Taking these large pumps off the grid is a good first step to wards power independence for most commercial farmers. We have developed our own in-house software that takes all variables into consideration and provides a pre-installation rapport of exactly how the system will perform in your area and your borehole (or river). We have good references of farmers that have followed this route and have saved money by doing so. We have dedicated staff and installers that specialise in Veichi Pump Drive projects.                        </Typography>

                    </Grid>
                </Grid>


            </Container>
        </Layout>
    )
}

export default Products
